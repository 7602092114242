@use './variables/colors' as colors;

.container {
  display: flex;
  width: 100%;
  max-width: 100vw;
  background-color: colors.$light-gray;
  height: 100%;
  min-height: 100vh;

  > div:nth-child(2) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    width: 100%;
  }
}

.pageHeader {
  display: flex;
  margin: 8rem 0 6rem 0;

  > span {
    margin-left: 2rem;
    :last-child {
      font-size: 2.4rem;
      font-weight: 400;
      margin-top: 0.54rem;
      color: colors.$dark-gray;
    }
  }
}

.margin-10 {
  margin: 10px;
}
