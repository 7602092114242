@use "styles/index.scss";
@use './../node_modules/ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
@use ' ./../node_modules/ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

html, body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h1 {
  font-size: 3.2rem;
  font-weight: 600;
  margin: 0;
}

h2 {
  font-size: 2.6rem;
  margin: 0;
}
