$projectWidth: 1/3 * 100% - 3;

.Project {
  width: $projectWidth;
  padding: 10px 10px;
  margin: 10px 5px 10px 0;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    scale: 1.02;
  }
}
