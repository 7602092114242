.Button {
  border: none;
  padding: 1rem 1.5rem;
  border-radius: 4px;
  font-family: sans-serif;
  cursor: pointer;
  width: 100%;

  &-primary {
    background-color: #1b3975;
    color: white;
  }

  &-secondary {
    background-color: white;
    color: #1b3975;
  }

  &:disabled {
    opacity: 0.54;
    cursor: not-allowed;
  }
}
