@use "./../../../styles/variables/colors.scss" as colors;

.Box {
  display: flex;
  flex-direction: column;
  padding: 1.6rem 1.6rem;
  margin: 0.4rem 0 0.4rem 0;

  background-color: colors.$white;
  border-radius: 0.24rem;
  box-shadow: 2px 2px 4px 0px darken(colors.$light-gray, 10%);
}

.CanidadatesTable {
  @extend .Box;
  height: 75%;
}

.CanidadateBox {
  @extend .Box;
  padding: 1rem 1rem;
  flex-direction: row;

  &:hover {
    scale: 1.01;
    box-shadow: 4px 4px 8px 0px darken(colors.$light-gray, 10%);
    cursor: pointer;
  }

  &Image {
    display: inline-block;
    width: 9rem;
    height: 9rem;
    line-height: 5rem;
    background-color: colors.$light-gray;
    border-radius: 4px;
    text-align: center;
    font-size: 1.75rem;
    overflow: hidden;
    margin: 0 1.5rem 0 0;

    > img {
      width: 100%;
      height: 100%;

    }
  }

  &Title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0 0 0.5rem 0;

    span {
      border-right: 1px solid darken(colors.$light-gray, 10%);
      padding-right: 1.5rem;
      margin-right: 1.5rem;

      &:last-of-type {
        border-right: unset;
      }
    }
  }

  &Infos {
    display: flex;
    flex-direction: column;
    width: 20rem;
    overflow: hidden;
    font-size: 1.2rem;

    padding-right: 1.5rem;
    margin-right: 1.5rem;
    border-right: 1px solid darken(colors.$light-gray, 10%);

    span {
      margin: 0 0 0.5rem 0;

      &:last-of-type {
        margin: unset;
      }
    }
  }
}

.Messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  >div:last-child {
    border-bottom: 1px solid #d0d0d0;
  }
}

.Message {
  padding: 1rem;
  border-top: 1px solid #d0d0d0;
  width: 80%;
}
